import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
  NgZone
} from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { Collateral } from '../../models/collateral.model';
import { CollateralSearchParty } from '../../models/collateral-search-party.model';
import { CCMCCollateralDetailsDialogComponent } from './collateral-details-dialog/collateral-details-dialog.component';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { PrecisionService } from '../../services/precision.service';
import { SpinnerService } from '../../services/spinner.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { AssetService } from 'src/@ccmc/services/asset.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDragHandle
} from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { MiserService } from 'src/@ccmc/services/miser.service';
import { ActiveCoreService } from 'src/@ccmc/services/active-core.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { ConditionsService } from 'src/@ccmc/services/conditions.service';
@Component({
  selector: 'ccmc-collateral-search',
  templateUrl: './collateral-search-dialog.component.html',
  styleUrls: ['./collateral-search-dialog.component.scss']
})
export class CCMCCollateralSearchDialogComponent implements OnInit, OnDestroy {
  searchPartyNameData: Array<CollateralSearchParty> = [];
  collateralSearchDataSource: any = new MatTableDataSource();
  collateralAssignmentDataSource: any = new MatTableDataSource();
  @ViewChild('bottomTable') table: MatTable<Collateral>;
  collateralSubscription: Subscription;
  tableColumns = ['name', 'type', 'status', 'description', 'assignedParty'];
  bottomTableColumns = [
    'name',
    'type',
    'status',
    'description',
    'assignedParty'
  ];
  @ViewChild('collateralSearchSort')
  collateralSearchSort: MatSort;
  selectedSearchCollateral: Array<any> = [];
  selectedAssignCollateral: Array<any> = [];
  assignPartyDisabled = true;
  continueDisabled = true;
  collateral: any[] = [];
  parties: any[] = [];
  showSpinner: boolean; // flag for the mat-spinner
  checkContinueDisabledBoolean = true;
  private spinnerSub: Subscription;
  originalCollateral: any;

  /**
   * Creates an instance of CCMCCollateralSearchDialogComponent.
   * @param {MatDialogRef<CCMCCollateralSearchDialogComponent>} dialogRef
   * @param {CcmcApiService} ccmcApiService
   * @param {PrecisionService} precisionService
   * @param {MatDialog} dialog
   * @param {ChangeDetectorRef} cd
   * @param {SpinnerService} spinnerService
   * @param {NgZone} zone
   * @param {AssetService} assetService
   * @memberof CCMCCollateralSearchDialogComponent
   */
  constructor(
    private dialogRef: MatDialogRef<CCMCCollateralSearchDialogComponent>,
    private ccmcApiService: CcmcApiService,
    private precisionService: PrecisionService,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private zone: NgZone,
    private assetService: AssetService,
    private adminApiService: AdminApiService,
    private miserService: MiserService,
    private activeCoreSerivce: ActiveCoreService,
    private amplifyService: AmplifyService,
    private conditionsService: ConditionsService
  ) {}

  /**
   * On Init
   *
   * @memberof CCMCCollateralSearchDialogComponent
   */
  ngOnInit() {
    // Init collateral to an empty array
    this.collateral = [];
    // Grabs the CIF for each borrower on the loan
    this.PartyCIFs();
    // Uses the collateral Search object to grab the collateral fields from the loan
    this.currentCollateral();
    // init sort
    this.collateralSearchDataSource.sort = this.collateralSearchSort;
    // this.collateralAssignmentDataSource.sort = this.collateralAssignmentSort;
    this.spinnerSub = this.spinnerService.spinner.subscribe(spinner => {
      this.showSpinner = spinner;
    });
  }

  /**
   * Current Collateral
   * @description Uses the collateral Search object to grab the collateral fields from the loan
   * @memberof CCMCCollateralSearchDialogComponent
   */
  currentCollateral() {
    // init nextCollateral to true
    let nextCollateral = true;
    // init flag count to 1
    let collateralCount = 1;
    while (nextCollateral && collateralCount < 6) {
      // Replace * with count
      let collateralFields =
        this.ccmcApiService.search.collateralSearch.collateralFields.replace(
          /\*/g,
          collateralCount
        );
      // split collateral fields into array
      collateralFields = collateralFields.split(', ');
      // Replace * with collateral count
      const typeField =
        this.ccmcApiService.search.collateralSearch.type.replace(
          '*',
          collateralCount
        );
      let subTypeField: any;
      if(this.assetService.getSelectedCore() === 'miser') {
        subTypeField =
        this.ccmcApiService.search.collateralSearch.collateralSubType.replace(
          '*',
          collateralCount
        );
      }
      // init collateralGroup
      const collateralGroup: any = {};

            collateralGroup.fields = this.ccmcApiService.mapping.filter(
        (obj: any) => {
          return (
            obj.fieldID.includes(collateralFields[0]) ||
            obj.fieldID.includes(collateralFields[1]) ||
            obj.fieldID.includes(collateralFields[2])
          );
        }
      );
      
      // update collateralGroup name
      if(this.assetService.getSelectedCore() === 'precision') {
        collateralGroup.name = `COLL_0${this.collateral.length + 1}`;
      } else {
        collateralGroup.name = `XC${this.collateral.length + 1}`;
      }
      console.log('collateral group',collateralGroup);
      // get index of typeField
      const typeIndex = collateralGroup.fields.findIndex(
        (obj: any) => obj.fieldID === typeField
      );
      // set type for collateralGroup
      if (typeIndex > -1) {
        collateralGroup.type = collateralGroup.fields[typeIndex].fieldValue;
      }

      if (this.assetService.getSelectedCore() === 'miser') {
        // get index of subTypeField
        const subTypeIndex = collateralGroup.fields.findIndex(
          (obj: any) => obj.fieldID === subTypeField
        );
        // set type for collateralGroup
        if (subTypeIndex > -1) {
          collateralGroup.collateralSubType = collateralGroup.fields[subTypeIndex].fieldValue;
        }

        // get index of collateral description
        const descriptionIndex = collateralGroup.fields.findIndex(
          (obj: any) => obj.fieldID === 'XC1_39'
        );
        // set type for collateralGroup
        if (descriptionIndex > -1) {
          collateralGroup.description = collateralGroup.fields[descriptionIndex].fieldValue;
        }
      }

      // tslint:disable-next-line:max-line-length
      const existingIndex = collateralGroup.fields.findIndex(
        (obj: any) =>
          obj.fieldID ===
          this.ccmcApiService.search.collateralSearch.existing.replace(
            '*',
            collateralCount
          )
      );
      // Set status for collateralGroup
      if (existingIndex > -1) {
        collateralGroup.status =
          collateralGroup.fields[existingIndex].fieldValue === ''
            ? 'NEW'
            : 'EXISTING';
      }
      collateralGroup.key =
        this.ccmcApiService.search.collateralSearch.key.replaceAll(
          '*',
          collateralCount
        );
      // get keyIndex
      const keyIndex = collateralGroup.fields.findIndex(
        (obj: any) =>
          obj.fieldID ===
          this.ccmcApiService.search.collateralSearch.key
            .split(', ')[0]
            .replace('*', collateralCount)
      );
      // Set assigned party
      if (keyIndex > -1) {
        collateralGroup.assignedParty =
          collateralGroup.fields[keyIndex].fieldValue;
      }
      if (
        collateralGroup.status === 'NEW' &&
        (collateralGroup.percentOfValue === undefined ||
          collateralGroup.percentOfValue === '')
      ) {
        collateralGroup.percentOfValue = 0;
      }
      // Get Current Transaction
      let transactionIndex = this.ccmcApiService.transactions.findIndex(
        (transaction: any) => transaction.transactionID === collateralGroup.name
      );
      // If collateralGroup has type push it to collateral
      if (
        collateralGroup.type !== '' &&
        transactionIndex > -1 &&
        this.ccmcApiService.transactions[transactionIndex].display
      ) {
        // push to collateral
        this.collateral.push(collateralGroup);
        // update table display
        this.collateralSearchDataSource = new MatTableDataSource(
          this.collateral
        );
        // update assignment table display
        this.collateralAssignmentDataSource = new MatTableDataSource(
          this.collateral
        );
      } else if (
        this.assetService.getSelectedCore() === 'precision' && 
        collateralGroup && 
        collateralGroup.type && 
        collateralGroup.type !== ''
      ) {

         // push to collateral
         this.collateral.push(collateralGroup);
         // update table display
         this.collateralSearchDataSource = new MatTableDataSource(
           this.collateral
         );
         // update assignment table display
         this.collateralAssignmentDataSource = new MatTableDataSource(
           this.collateral
         );

      } else {
        // Set nextCollateral to false to finish loop
        nextCollateral = false;
      }
      collateralCount++;
    }
  }

  /**
   * Party CIFs
   * @description Grabs the CIF for each borrower on the loan
   * @memberof CCMCCollateralSearchDialogComponent
   */
  PartyCIFs() {
    // let nextBorrower = true;
    let borrowerCount = 1;
    this.parties = [];
    // temp hard coded count, since the cifs are not in sequence order in the mapping
    while (borrowerCount < 27) {
      let CIFField = '';
      if (borrowerCount < 10) {
        CIFField = this.ccmcApiService.search.collateralSearch.CIF.replace(
          '*',
          borrowerCount
        );
      } else {
        CIFField = this.ccmcApiService.search.collateralSearch.CIF.replace(
          '0*',
          borrowerCount
        );
      }
      const borrowerCIFIndex = this.ccmcApiService.mapping.findIndex(
        (obj: any) => obj.fieldID === CIFField
      );
      // If index is exists
      if (borrowerCIFIndex > -1) {
        // grab the CIF
        const CIF = this.ccmcApiService.mapping[borrowerCIFIndex].fieldValue;
        // If CIF is not empty push it to parties
        if (CIF !== '') {
          this.parties.push(CIF);
        } else {
          console.log(CIF);
        }
      } else {
        console.log(CIFField);
        // nextBorrower = false;
      }
      borrowerCount++;
    }
  }

  /**
   * Search Existing
   * @description performs a search for the collateral to add to the loan
   * @param {*} selectedParty
   * @memberof CCMCCollateralSearchDialogComponent
   */
  searchExisting(selectedParty: any) {
    // Clear out previous searched collateral
    if(this.assetService.getSelectedCore() === 'precision') {
      this.collateral = this.collateral.filter(collateralItem =>
        collateralItem.name.toLowerCase().includes('coll')
      );
    } else {
      this.collateral = this.collateral.filter(collateralItem =>
        collateralItem.name.toLowerCase().includes('xc')
      );
    }
    this.collateralSearchDataSource.data = this.collateral;

    // init searchCollateralrequest object
    let searchCollateralrequest: any = {
      configurations: this.ccmcApiService.configurations,
      cif: selectedParty,
      cognitoID: this.amplifyService.getCognitoId(),
      fields: this.collateral[0].fields,
      mapping: this.ccmcApiService.mapping,
      type: this.collateral[0].type
    };
    if(this.assetService.getSelectedCore() === 'miser') {
      searchCollateralrequest.collateralType = this.collateral[0].type;
      searchCollateralrequest.collateralSubType = this.collateral[0].collateralSubType;
    }
    // Init request object
    const request = {
      content: searchCollateralrequest,
      assetID: this.assetService.getSelectedAssetId()
    };
    // set spinner to true
    this.spinnerService.setShowSpinner(true);
    // Perform collateral search

    if(this.assetService.getSelectedCore() === 'precision') {
      this.precisionService.searchCollateral(request).subscribe(result => {
        // set spinner to false
        this.spinnerService.setShowSpinner(false);
        // Parse result
        const parsedResult = JSON.parse(JSON.stringify(result));
        // Catch timeout
        if (parsedResult.status === 504) {
          const errorMessage = {
            message: 'Endpoint request timed out',
            title: 'Collateral Search Error'
          };
          const dialogRef1 = this.zone.run(() => {
            this.dialog.open(ErrorDialogComponent, {
              data: errorMessage
            });
          });
        } else  if (parsedResult.statusMessage.includes('There is no collateral for this party')) {
          // Custom response handling for no collaterals
          const message = {
            message: 'There is no collateral for this party.',
            title: 'Collateral Search Response'
          }
          const dialogRef1 = this.zone.run(() => {
            this.dialog.open(ErrorDialogComponent, {
              data: message
            });
          });
          // Unsuccessful search
        } else if (parsedResult.statusFlag === false) {
          const errorMessage = {
            message: parsedResult.statusMessage,
            title: 'Collateral Search Error'
          };
          // open error dialog with mesage
          const dialogRef1 = this.zone.run(() => {
            this.dialog.open(ErrorDialogComponent, {
              data: errorMessage
            });
          });
          // Successful search, but no results
        } else if (
          parsedResult.statusFlag === true &&
          parsedResult.content.length === 0
        ) {
          const errorMessage = {
            message: 'No Collateral Found',
            title: 'Collateral Search'
          };
          const dialogRef1 = this.zone.run(() => {
            this.dialog.open(ErrorDialogComponent, {
              data: errorMessage
            });
          });
          let fakeChxbox = {
            checked: true
          };
          for (let item of this.collateral) {
            this.selectingCollateral(fakeChxbox, item);
          }
          let searchValue = `${this.parties[0]}`;
          this.addCollateral(searchValue);
        } else {
          // Display new collateral to screen
          this.collateral = this.collateral.concat(parsedResult.content);
          this.collateralSearchDataSource = new MatTableDataSource(
            this.collateral
          );
        }
      });
    } else if (this.assetService.getSelectedCore() === 'miser') {
      this.miserService.searchCollateral(request).subscribe(result => {
        // set spinner to false
        this.spinnerService.setShowSpinner(false);
        // Parse result
        const parsedResult = JSON.parse(JSON.stringify(result));
        // Catch timeout
        if (parsedResult.status === 504) {
          const errorMessage = {
            message: 'Endpoint request timed out',
            title: 'Collateral Search Error'
          };
          const dialogRef1 = this.zone.run(() => {
            this.dialog.open(ErrorDialogComponent, {
              data: errorMessage
            });
          });
          // Unsuccessful search
        } else if (parsedResult.statusFlag === false) {
          const errorMessage = {
            message: parsedResult.statusMessage,
            title: 'Collateral Search Error'
          };
          // open error dialog with mesage
          const dialogRef1 = this.zone.run(() => {
            this.dialog.open(ErrorDialogComponent, {
              data: errorMessage
            });
          });
          // Successful search, but no results
        } else if (
          parsedResult.statusFlag === true &&
          parsedResult.content.length === 0
        ) {
          const errorMessage = {
            message: 'No Collateral Found',
            title: 'Collateral Search'
          };
          const dialogRef1 = this.zone.run(() => {
            this.dialog.open(ErrorDialogComponent, {
              data: errorMessage
            });
          });
          let fakeChxbox = {
            checked: true
          };
          for (let item of this.collateral) {
            this.selectingCollateral(fakeChxbox, item);
          }
          let searchValue = `${this.parties[0]}`;
          this.addCollateral(searchValue);
        } else {
          // Display new collateral to screen
          this.collateral = this.collateral.concat(parsedResult.content);
          this.collateralSearchDataSource = new MatTableDataSource(
            this.collateral
          );
        }
      });
    }
  }

  /**
   * Selecting Collateral
   * @description handles adding/removing collateral to the selectedSearchCollateral
   * @param {*} chkbx
   * @param {*} checked
   * @returns
   * @memberof CCMCCollateralSearchDialogComponent
   */
  selectingCollateral(chkbx: any, checked: any) {
    // If checkbox is checked we push it to selected search collateral
    if (chkbx.checked) {
      return this.selectedSearchCollateral.push({
        value: checked,
        chkbx
      });
    }
    // remove from selected search collateral
    this.selectedSearchCollateral = this.removeFromArray(
      this.selectedSearchCollateral,
      checked
    );
  }

  /**
   * Check Collateral Assignmnet Exists
   * @description determines if the element exists in the collateral assignment table
   * @param {*} element
   * @returns
   * @memberof CCMCCollateralSearchDialogComponent
   */
  checkCollateralAssignmentExists(element: any) {
    // Check if the element exists in collateral assignment
    return this.collateralAssignmentDataSource.data.findIndex(
      (obj: any) => obj.name === element.name
    );
  }

  /**
   * Check Continue Disabled
   * @description determines if continue should be be disabled
   * based upon whether all selected collateral has a value
   * @memberof CCMCCollateralSearchDialogComponent
   */
  checkContinueDisabled() {
    // console.log(this.collateralAssignmentDataSource);
    // Returns true or false depending on if there is an assigned party for every collateral assignment
    this.continueDisabled = this.collateralAssignmentDataSource.data.some(
      (item: any) => {
        if (!item.assignedParty) {
          this.checkContinueDisabledBoolean = true;
          return true;
        } else {
          this.checkContinueDisabledBoolean = false;
          return false;
        }
      }
    );
  }

  /**
   * Add Collateral
   * @description adds selected collateral to the collateral assignment table
   * @memberof CCMCCollateralSearchDialogComponent
   */
  addCollateral(groupCIF: any) {
    // init tempArray
    const tempArr: any[] = [];
    this.selectedSearchCollateral.forEach(collateral => {
      if (this.checkCollateralAssignmentExists(collateral.value) < 0) {
        collateral.value.assignedParty = groupCIF;
        tempArr.push(collateral.value);
      }
      collateral.chkbx.checked = false;
    });
    if (tempArr.length > 0) {
      // evaluate Conditions
      this.conditionsService.evaluateConditions();
      this.collateralAssignmentDataSource = new MatTableDataSource(
        this.collateralAssignmentDataSource.data.concat(tempArr)
      );
    }
    this.selectedSearchCollateral.length = 0;
  }

  /**
   * Add To Assign
   * @description handles selecting collateral from the collateral assignment table
   * @param {*} chkbx
   * @param {*} element
   * @memberof CCMCCollateralSearchDialogComponent
   */
  addToAssign(chkbx: any, element: any) {
    chkbx.checked
      ? this.selectedAssignCollateral.push({ chkbx, value: element })
      : (this.selectedAssignCollateral = this.removeFromArray(
          this.selectedAssignCollateral,
          element
        ));
    this.checkAssignPartyDisabled();
  }

  /**
   * Assign To Selected
   * @description assigns a party to the selected collateral
   * @param {*} value
   * @memberof CCMCCollateralSearchDialogComponent
   */
  assignToSelected(value: any) {
    // set assign party disabled to false
    this.assignPartyDisabled = false;
    // loop through each selected assign collateral and set the assign party and check box
    this.selectedAssignCollateral.forEach(collateral => {
      collateral.value.assignedParty = value;
      collateral.chkbx.checked = false;
    });
    // Reset length to 0
    this.selectedAssignCollateral.length = 0;
    // evaluate Conditions
    this.conditionsService.evaluateConditions();
    // Check if Assign party disabled
    this.checkAssignPartyDisabled();
  }

  /**
   * Send Loan
   * @description finalizes the collateral data and updates the loan data
   * closes dialog with true, which is the flag for exporting the loan
   * @memberof CCMCCollateralSearchDialogComponent
   */
  sendLoan() {
    if (this.activeCoreSerivce.activeCore && this.activeCoreSerivce.activeCore === 'precision') {
      let collateralCount = 1;
      let collateralUpdateFields =
        this.ccmcApiService.search.collateralSearch.collUpdateFields.replace(
          /\*/g,
          collateralCount
        );
      // split collateral fields into array
      collateralUpdateFields = collateralUpdateFields.split(',');
      for (let i = 0; i < this.collateralAssignmentDataSource.data.length; i++) {
        this.collateralAssignmentDataSource.data[i].fields.forEach((c: any) => {
          // Get index for fieldID
          const index = this.ccmcApiService.mapping.findIndex(
            (obj: any) => obj.fieldID === c.fieldID.replace('*', `${i + 1}`)
          );
          // Set fieldValue if available
          if (index > -1) {
            if(collateralUpdateFields.includes(this.ccmcApiService.mapping[index].fieldID) &&
              this.ccmcApiService.mapping[index].fieldValue !== '') {
                c.fieldValue = this.ccmcApiService.mapping[index].fieldValue;
            } 
            
          }
        });
        let collateralItemsFilter = this.ccmcApiService.mapping.filter((coll: any) => 
        coll.fieldDisplay.includes('Collateral Description') ||
        coll.fieldDisplay.includes('Comments Line')
        )
        collateralItemsFilter.forEach((coll: any) => {
          const collIndex = this.collateralAssignmentDataSource.data[0].fields.findIndex((collFields: any) => coll.fieldID === collFields.fieldID.replace('*', `${i + 1}`));

          if (collIndex === -1) {
            const collMappingIndex = this.ccmcApiService.mapping.findIndex(
              (obj: any) => obj.fieldID === coll.fieldID
            ); 
            if(collMappingIndex > -1) {
              // this.ccmcApiService.mapping[collMappingIndex].fieldValue = '';
            }
          }
        });
      }
    }

    console.log('collateral assignment data source', this.collateralAssignmentDataSource.data);
    // Loop through assignmnet data  and get keys for collateral
    for (let i = 0; i < this.collateralAssignmentDataSource.data.length; i++) {
      if (this.collateralAssignmentDataSource.data[i] && this.collateralAssignmentDataSource.data[i].key) {
        console.log(
          this.collateralAssignmentDataSource.data[i].key.replace(
            /\*/g,
            `${i + 1}`
          )
        );
        // init keys
        const keys = this.collateralAssignmentDataSource.data[i].key
          .replace(/\*/g, `${i + 1}`)
          .split(', ');
        keys.forEach((k: any) => {
          // get index for each key
          const keyIndex = this.ccmcApiService.mapping.findIndex(
            (obj: any) => obj.fieldID === k
          );
          // if key exists set the value appropriete field value
          if (keyIndex > -1) {
            this.ccmcApiService.mapping[keyIndex].fieldValue =
              this.collateralAssignmentDataSource.data[i].assignedParty;
          }
        });
      }
    }

    // Adds sequence number for precision
    if (this.activeCoreSerivce.activeCore && this.activeCoreSerivce.activeCore === 'precision') {
      for (let i = 0; i < this.collateralAssignmentDataSource.data.length; i++) {
        // init keys
        const key = 'COLL_0*_002'.replace(
            /\*/g,
            `${i + 1}`
          )
        // get index for each key
        const keyIndex = this.ccmcApiService.mapping.findIndex(
          (obj: any) => obj.fieldID === key
        );
        // if key exists set the value appropriate field value
        if (keyIndex > -1) {
          const assignIndex = this.collateralAssignmentDataSource.data[i].fields.findIndex(
            (obj: any) => obj.fieldID === 'COLL_0*_002'
          );
          if (assignIndex > -1) {
            this.ccmcApiService.mapping[keyIndex].fieldValue =
            this.collateralAssignmentDataSource.data[i].fields[assignIndex].fieldValue;
          }
        }
      }
    }

    if (this.activeCoreSerivce.activeCore && this.activeCoreSerivce.activeCore === 'miser') {
      for (let i = 0; i < this.collateralAssignmentDataSource.data.length; i++) {
        if (this.collateralAssignmentDataSource.data[i] && !this.collateralAssignmentDataSource.data[i].key) {
          // init key
          const keys: any = ['XCM_02', 'XCL_02', 'XC1_02'];
          for (let key of keys) {
            // get index for key
            const keyIndex = this.ccmcApiService.mapping.findIndex(
              (obj: any) => obj.fieldID === key
            );
            // if key exists set the value appropriate field value
            if (keyIndex > -1) {
              this.ccmcApiService.mapping[keyIndex].fieldValue =
              this.collateralAssignmentDataSource.data[i].collateralID;
            }
          }
        }
      }
    }

    // Evaluate new values with conditions
    this.conditionsService.evaluateConditions();

    // close dialog
    this.closeDialog(true);
  }

  /**
   * Remove
   * @description removes the selected collateral from the collateral assignment table
   * @memberof CCMCCollateralSearchDialogComponent
   */
  remove() {
    // loop through selected assign collateral
    this.selectedAssignCollateral.forEach(element => {
      // remove the element from the the collateral assignment data source
      this.collateralAssignmentDataSource.data = [
        ...this.removeFromArray(
          this.collateralAssignmentDataSource.data,
          element.value
        )
      ];
    });
    // Set length to 0
    this.selectedAssignCollateral.length = 0;
    this.checkAssignPartyDisabled();
  }

  /**
   * Remove From Array
   * @description removes an object from an array
   * @param {*} arr
   * @param {*} obj
   * @returns {Array<any>}
   * @memberof CCMCCollateralSearchDialogComponent
   */
  removeFromArray(arr: any, obj: any) {
    const indx = arr.findIndex((elem: any) => {
      if (elem.value) {
        return elem.value.name === obj.name;
      }
      return elem.name === obj.name;
    });
    if (indx > -1) {
      arr.splice(indx, 1);
      return arr;
    }
  }

  /**
   * Remove All
   * @description removes all collateral from the collateralAssignment table
   * @memberof CCMCCollateralSearchDialogComponent
   */
  removeAll() {
    // Set data to []
    this.collateralAssignmentDataSource.data = [];
    // disable assignparty button
    this.assignPartyDisabled = true;
  }

  /**
   * Show Details
   * @description shows additional fields for all collateral
   * @memberof CCMCCollateralSearchDialogComponent
   */
  showDetails() {
    this.dialog.open(CCMCCollateralDetailsDialogComponent, {
      data: this.collateralSearchDataSource.data,
      panelClass: 'collateral-details__dialog'
    });
  }

  /**
   * Check Assign Party Disabled
   * @description determines if the assign party button should should disabled
   * based upon whether collateral in assign collateral is selected
   * @memberof CCMCCollateralSearchDialogComponent
   */
  checkAssignPartyDisabled() {
    this.assignPartyDisabled =
      this.selectedAssignCollateral.length === 0 ? true : false;
  }

  /**
   * Close Dialog
   * @description closes the dialog with the sendLoan flag (true or false)
   * @param {*} sendLoan
   * @memberof CCMCCollateralSearchDialogComponent
   */
  closeDialog(sendLoan: any) {
    this.dialogRef.close(sendLoan);
  }

  /**
   * On Destroy
   * Unsubscribe from observables
   * @memberof CCMCCollateralSearchDialogComponent
   */
  ngOnDestroy() {
    if (this.collateralSubscription) {
      this.collateralSubscription.unsubscribe();
    }
    if (this.spinnerSub) {
      this.spinnerSub.unsubscribe();
    }
  }

  /**
   * Drop Table
   * @description Drag and drop feature for material table
   * @param {CdkDragDrop<Collateral[]>} event
   * @memberof CCMCCollateralSearchDialogComponent
   */
  dropTable(event: CdkDragDrop<Collateral[]>) {
    const prevIndex = this.collateralAssignmentDataSource.data.findIndex(
      (d: any) => d === event.item.data
    );
    moveItemInArray(
      this.collateralAssignmentDataSource.data,
      prevIndex,
      event.currentIndex
    );
    this.table.renderRows();
  }
}
